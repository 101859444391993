import axios from 'axios';
import { decryptToken } from "../assets/js/crypto-utils";

export default class Axios {
  static failedRequestQueue = [];
  static isRefreshing = false; // Indica se o modal de relogin está ativo
  
  constructor(API_URL = process.env.VUE_APP_API_BASE_URL + '/') {
    this.API_URL = API_URL;
    this.api = axios.create({
      baseURL: this.API_URL,
    });
    this.isTokenValid = true;
    this.initializeRequestInterceptor();
    this.initializeResponseInterceptor();
  }

  async useAxios(method, url, data = null, params = {}, skipAuth = false, headers = {}) {
    if (!this.isTokenValid && !skipAuth) {
      // Enfileira a requisição caso o token não seja válido
      return new Promise((resolve, reject) => {
        Axios.failedRequestQueue.push({
          resolve, reject, method, url, data, params, skipAuth, headers
        });
      });
    }
  
    try {
      const authHeader = skipAuth ? {} : { Authorization: `Bearer ${decryptToken()}` };
      const finalHeaders = { ...authHeader, ...headers };
  
      const response = await this.api.request({
        method,
        url,
        data,
        params,
        headers: finalHeaders
      });
  
      return response;
    } catch (error) {
      throw error;
    }
  }

  initializeRequestInterceptor() {
    this.api.interceptors.request.use(config => {
      if (!config.headers.skipAuth) {
        const token = decryptToken();
        if (token) {
          config.headers['Authorization'] = `Bearer ${token}`;
        } else {
          return Promise.reject(new Error("Token ausente. Acesso negado."));
        }
      }
      return config;
    }, error => Promise.reject(error));
  }

  initializeResponseInterceptor() {
    this.api.interceptors.response.use(response => response, error => {
      if (error.response && error.response.status === 401 && !error.config.headers.skipAuth) {
        if (!Axios.isRefreshing) {
          Axios.isRefreshing = true;
          this.showReloginModal();
        }
        return new Promise((resolve, reject) => {
          Axios.failedRequestQueue.push({
            resolve, reject, config: error.config
          });
        });
      }
      return Promise.reject(error);
    });
  }

  async retryFailedRequests() {
    this.isTokenValid = true;
    const queue = [...Axios.failedRequestQueue];
    
    Axios.failedRequestQueue = [];
    for (const el of queue) {
        try {
            const i = el.config;
            const response = await this.useAxios(i.method, i.url, i.data, i.params, i.skipAuth);
            el.resolve(response);
        } catch (error) {
            el.reject(error);
        }
    }
    Axios.isRefreshing = false;
  }

  clearFailedRequests() {
    Axios.failedRequestQueue = [];
  }

  showReloginModal() {
    const modalRelogin = $('#modal_relogin');
  
    if (modalRelogin.length) {
      modalRelogin.modal('show');
      modalRelogin.on('hidden.bs.modal', () => {
        this.retryFailedRequests();
      });
    }
  }
}
