import { decryptToken } from "../assets/js/crypto-utils";

export default function authHeader() {
  let user = decryptToken();
  if (user) {
    return {
      Authorization: "Bearer " + user,
      "Content-Type": "application/vnd.api+json",
      "Accept": "application/vnd.api+json",
    };
  } else {
    return {};
  }
}
