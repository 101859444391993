<template>
  <div v-if="loading"  class="loading-overlay">
    <div class="loading-spinner"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    show() {
      this.loading = true;
    },
    hide() {
      this.loading = false;
    },
  },
};
</script>


<style scoped>
.loading-overlay {
  position: absolute; /* Mudança para absolute */
  width: calc(100% - 16rem);
  height: 100%;
  min-height: 80vh;
  max-height: fit-content;
  background-color: rgb(255 255 255 / 3%);
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Assegure que o overlay fique acima do conteúdo */
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 5px solid rgb(250, 138, 9);
  border-top-color: white;
  border-radius: 50%;
  animation: spin 1s infinite linear;
}

@media(max-width: 575px) {
  .loading-overlay {
    width: 100%;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>