import CryptoJS from "crypto-js";

const key = process.env.VUE_APP_AES_KEY;
const iv = process.env.VUE_APP_AES_IV;

export const encryptData = (data) => {
  try {
    if (typeof data !== 'string') {
      data = JSON.stringify(data);
    }

    const keyUtf8 = CryptoJS.enc.Utf8.parse(key);
    const ivUtf8 = CryptoJS.enc.Utf8.parse(iv);
    const encrypted = CryptoJS.AES.encrypt(data, keyUtf8, {
      iv: ivUtf8,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
  } catch (error) {
    console.error('Erro ao criptografar os dados:', error);
    throw error;
  }
};

export const decryptData = (data) => {
  try {
    const keyUtf8 = CryptoJS.enc.Utf8.parse(key);
    const ivUtf8 = CryptoJS.enc.Utf8.parse(iv);
    const decrypted = CryptoJS.AES.decrypt(data, keyUtf8, {
      iv: ivUtf8,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);

    try {
      const parsedData = JSON.parse(decryptedText);
      return parsedData;
    } catch (e) {
      return decryptedText;
    }
  } catch (error) {
    console.error('Erro ao descriptografar os dados:', error);
    throw error;
  }
};

export const saveToLocalStorage = (storageKey, data) => {
  try {
    const encryptedData = encryptData(data);
    localStorage.setItem(storageKey, encryptedData);
  } catch (error) {
    console.error('Erro ao salvar os dados no localStorage:', error);
  }
};

export const loadFromLocalStorage = (storageKey) => {
  try {
    const encryptedData = localStorage.getItem(storageKey);
    if (encryptedData) {
      const decryptedData = decryptData(encryptedData);
      return decryptedData;
    }
    return null;
  } catch (error) {
    localStorage.removeItem("cartItems");
    console.error('Erro ao carregar os dados do localStorage:', error);
    return null;
  }
};

export const decryptToken = () => {
  try {
    var token = localStorage.getItem("token");
    if (token.startsWith('"') && token.endsWith('"')) {
      token = token.slice(1, -1);
    }
    const decryptedToken = decryptData(token);
    return decryptedToken;
  } catch (error) {
    localStorage.removeItem("token");
    console.error('Erro ao descriptografar o token:', error);
    return null;
  }
};