import { createStore } from "vuex";
import { auth } from "./auth.module";
import { reset } from "./reset.module";
import { cart } from "./cart.module";
import { saveToLocalStorage, loadFromLocalStorage } from "../assets/js/crypto-utils.js";
import router from "../router/index.js";
import { get } from "jquery";


export default createStore({
  state: {
    hideConfigButton: false,
    showConfig: false,
    isTransparent: "bg-full",
    color: "primary",
    idDisciplina: null,
    newNotifications: [],
    permissoesUser: [],
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    minSidenav: window.innerWidth < 575 ? true : false,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    titlePage: null,
    breadcrumbItems: [],
    navbarFixed:
      "position-sticky blur shadow-blur left-auto top-1 z-index-sticky px-0 mx-4",
    absolute: "position-absolute px-4 mx-0 w-100 z-index-2"
  },
  modules: {
    auth,
    reset,
    cart
  },
  mutations: {
    toggleConfigurator(state, value) {
      if (typeof value === 'boolean') {
        state.showConfig = value;
      } else {
        state.showConfig = !state.showConfig;
      }
    },
   
    navbarMinimize(state, value) {
      
      if (value === "minimize") {
        state.minSidenav = true;
      } else if (value === "maximize") {
        state.minSidenav = false;
      } else {
        state.minSidenav = !state.minSidenav;
      }
    },
    sidebarType(state, payload) {
      state.isTransparent = payload;
    },
    cardBackground(state, payload) {
      state.color = payload;
    },
    navbarFixed(state) {
      state.isNavFixed = !state.isNavFixed;
    },
    toggleEveryDisplay(state) {
      state.showNavbar = !state.showNavbar;
      state.showSidenav = !state.showSidenav;
      state.showFooter = !state.showFooter;
    },
    toggleHideConfig(state, status) {
      state.hideConfigButton = status;
    },
    atualizarCorDinamica(state, novaCor) {
      state.corDinamica = novaCor;
    },
    atualizarNewNotifications(state, notifications) {
      state.newNotifications = notifications;
    },
    atualizarPermissoes(state, permissoes) {
      state.permissoesUser = permissoes;
    },
    setIdDisciplina(state, idDisciplina) {
      state.idDisciplina = idDisciplina;
    },
    setBreadcrumbItems(state, items) {
      state.breadcrumbItems = items;
    },
    setTitlePage(state, title) {
      state.titlePage = title;
    },
  },
  actions: {
    toggleSidebarColor({ commit }, payload) {
      commit("sidebarType", payload);
    },
    setCardBackground({ commit }, payload) {
      commit("cardBackground", payload);
    },
    atualizarCorDinamica({ commit }, novaCor) {
      commit("atualizarCorDinamica", novaCor);
    },
    atualizarNewNotifications({ commit }, notifications) {
      commit('atualizarNewNotifications', notifications);
    },
    atualizarPermissoes({ commit }, permissoes) {
      commit('atualizarPermissoes', permissoes);
    },
    getRouteByName({ commit }, routeName) {
      const route = router.getRoutes().find(route => route.name === routeName);
      return route;
    },
    async fetchParentRoutes({ dispatch }, route) {
      const parents = [];
      let currentRoute = route;
      while (currentRoute.meta && currentRoute.meta.parent) {
        const parent = await dispatch('getRouteByName', currentRoute.meta.parent);
        if (parent) {
          parents.unshift(parent);
          currentRoute = parent;
        } else {
          break;
        }
      }
      return parents;
    },
    async updateBreadcrumb({ commit, dispatch }, route) {
      const items = [];
      const parentRoutes = await dispatch('fetchParentRoutes', route);
  
      for (const parentRoute of parentRoutes) {
        items.push({
          label: parentRoute.meta.breadcrumb || parentRoute.name,
          route: {
            name: parentRoute.name,
            params: route.params
          }
        });
      }
      for (const r of route.matched) {
        if (r.name) {
          items.push({
            label: r.meta.breadcrumb || r.name,
            route: {
              name: r.name,
              params: route.params
            },
            active: true
          });
        }
      }
      commit('setBreadcrumbItems', items);
    },
    updateCartItemsAction({ commit }) {
      commit('updateCartItems');
    }
  },
  getters: {
  },
});
